@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap');

html {
    scroll-behavior: smooth;
}

@layer base {
    body {
        @apply text-amber-900;
    }
    li {
        @apply ml-4;
        list-style-type: '⟐ ';
    }
    h1 {
        @apply text-5xl text-center;
        font-family: 'Nova Mono', monospace;
    }
    h2 {
        @apply text-lg text-center;
    }
    h3 {
        @apply text-lg;
    }

    a {
        @apply text-amber-400 font-bold;
    }

    button, [type='submit'] {
        @apply bg-amber-400 text-white font-bold rounded-lg p-2;
    }

    input, textarea {
        @apply border border-amber-400 rounded p-2;
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    textarea:focus, input:focus{
        @apply outline-amber-200;
    }
}
